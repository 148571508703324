<template>
  <div class="detail-main yt-flex-layout">
    <div class="bread-div yt-flex-layout">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="'/commodity/list'">商品列表</el-breadcrumb-item>
        <el-breadcrumb-item>查看详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--      content-->
    <div class="detail-content">
      <div class="basic-card card yt-flex-layout">
        <div class="card-header yt-flex-layout">基本信息</div>
        <div class="info-style yt-flex-layout">
          <div
            class="yt-flex-layout"
            style="margin-top: 20px"
            :style="index === 4 ? 'width: 100%' : 'width: 50%'"
            v-for="(b, index) in basicInfo"
            :key="index"
          >
            <span class="name" style="margin-right: 10px;white-space: nowrap">{{ b.name }}:</span>
            <span style="text-align: left;word-break: break-all">{{ b.key === 'state' ? getState(b.value) : b.value || '--' }}</span>
          </div>
        </div>
      </div>
      <div class="course-card card yt-flex-layout">
        <div class="card-header yt-flex-layout">课程信息</div>
        <div class="info-style yt-flex-layout" style="margin-top: 20px">
          <div class="img-style" :style="{ backgroundImage: 'url(' + courseInfo.url + ')' }" />
          <div class="p-style">
            <p>
              <span class="name wh-name">课程名称:</span
              ><span @click="toPath(`/course/detail/${courseInfo.courseId}`)" class="blue-style">{{ courseInfo.courseName }}</span>
            </p>
            <p>
              <span class="name wh-name">总课时:</span><span>{{ courseInfo.period }}课时</span>
            </p>
          </div>
        </div>
      </div>
      <div class="commodity-card card yt-flex-layout">
        <div class="card-header yt-flex-layout">商品信息</div>
        <div class="info-style yt-flex-layout">
          <div
            :style="index === 6 ? 'width: 100%' : 'width: 50%'"
            v-for="(c, index) in commodityInfo"
            :key="index"
            class="yt-flex-layout"
            style="margin-top: 20px"
          >
            <span class="name" style="margin-right: 10px;white-space: nowrap">{{ c.name }}:</span>
            <span v-if="c.key === 'price' || c.key === 'discountedPrice'" style="text-align: left;word-break: break-all">{{
              `${c.value ? `￥ ${c.value}` : '--'}`
            }}</span>
            <span v-else-if="c.key === 'feeType'">{{ c.value === 1 ? '收费' : '免费' }}</span>
            <span v-else-if="c.key === 'createTime'" style="text-align: left;word-break: break-all">{{
              $formatTime(c.value, 'yyyy-MM-dd hh:mm:ss')
            }}</span>
            <span v-else-if="c.key === 'timeLimit'" style="text-align: left;word-break: break-all">{{
              c.feeType === 1 ? `购买后${c.value}天` || '--' : '该课程是免费课程'
            }}</span>
            <span v-else-if="c.key === 'freeChapterIds'" style="text-align: left;word-break: break-all">{{
              c.value ? c.value.join('、') : '--'
            }}</span>
            <span v-else>{{ c.value || '--' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { basicInfo, commodityInfo } from '@/components/manage/commodity/commodityColumns'
import productApi from '@api/product'
export default {
  name: 'CommodityDetail',
  components: {},
  data() {
    return {
      basicInfo: basicInfo,
      courseInfo: {
        courseId: null,
        url: require('../../../assets/monitor/courseCover.jpg'),
        courseName: 'Java基本程序设计结构',
        period: null
      },
      detailId: null,
      commodityInfo: commodityInfo
    }
  },
  created() {
    this.detailId = this.$route.query['id']
    if (this.detailId) {
      this.getInfo()
    }
  },
  methods: {
    toPath(route) {
      this.$router.push(route)
    },
    getState(type) {
      switch (type) {
        case 0:
          return '未发布'
        case 1:
          return '已上架'
        case 2:
          return '已下架'
        case 3:
          return '已删除'
      }
    },
    getInfo() {
      productApi.commodityInfo(this.detailId).then(res => {
        this.basicInfo.forEach(b => {
          b.value = res.res[b.key]
        })
        this.courseInfo = {
          courseId: res.res['courseId'],
          url: res.res['cover'] || require('../../../assets/monitor/courseCover.jpg'),
          courseName: res.res['courseName'],
          period: 0
        }
        this.commodityInfo.forEach(c => {
          c.value = res.res[c.key]
          if (c.key === 'freeChapterIds') {
            c.value = []
            res.res.chapterInfoVOList.forEach(item => {
              if (item.type === 0) {
                c.value.push(item.name)
              }
            })
          }
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail-main {
  background-color: #f8f8f8;
  flex-direction: column;
  height: 100%;
  .bread-div {
    align-items: center;
    padding: 0 12px;
    height: 44px;
    background-color: #fff;
    margin-bottom: 20px;
  }
  .detail-content {
    padding: 0 20px;
    .card {
      flex-direction: column;
      background-color: #fff;
      border-radius: 6px;
      padding-bottom: 20px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    .info-style {
      flex-wrap: wrap;
      padding: 0 66px 0 60px;
      .font(14px, 500, #000);
      .name {
        color: #666;
      }
      .img-style {
        border: 1px solid #c2c2c2;
        background: no-repeat center;
        background-size: cover;
        .wh(120px, 90px);
        margin-right: 20px;
        border-radius: 4px;
      }
      .p-style {
        p {
          text-align: left;
          display: flex;
        }
        p:first-child {
          margin-bottom: 20px;
        }
        .wh-name {
          margin-right: 10px;
        }
        .blue-style {
          color: #448bff;
          cursor: pointer;
        }
      }
    }
    .card-header {
      .font(14px, 500, #000);
      padding-left: 20px;
      align-items: center;
      height: 40px;
      border-bottom: 1px solid #e2e4e8;
    }
  }
}
</style>
